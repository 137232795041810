function homeNewsBtn () {
    $('.js-volna-load-news').on('click', function(event) {
        var btn = $(this);
        var url = btn.data('load-url');
        var last = btn.data('last');
        var container = $(btn.data('container'));

        btn.prop('disabled', true).addClass('loading');

        $.ajax(url + last, {
            type: 'GET',
            statusCode: {
                200: function(response) {
                    if (!response.length) {
                        btn.hide();
                        return;
                    }

                    var tmp = $('<div/>');

                    $(response).each(function(i, e) {
                        tmp.append(smallNewsTemplate(e));

                        if (response.length - 1 == i) {
                            btn.data('last', e.last_at);
                            container.append(tmp.children());
                        }
                    });

                    tmp.children().each(function(i, e) {
                        e.fadeIn(600);
                    });

                    btn.prop('disabled', false).removeClass('loading');
                },
                422: function(response) {
                    console.log(response);
                },
                500: function(response) {
                    console.log(response);
                }
            }
        });
    });

    var smallNewsTemplate = function(data) {
        return `
            <li class="item-width-1fr news__item" style="display:none">
                <a class="wrap__news-img" href="${data.url}">
                    <img class="news-img" src="${data.img}">
                </a>
                <div class="wrap__news__descr">
                <div class="news__division">
                    <a href="${data.section.url}" style="color:#ee042c">${data.section.name}</a>
                </div>
                <a class="news__title" href="${data.url}">${data.name}</a>
                <div class="news__descr">${data.announce}</div>
                </div>
            </li>        
        `;
    }
}
module.exports = homeNewsBtn;
