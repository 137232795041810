const arr = [
        {
        "date" : "23/04/2019",
        "month": "March",
        "year": "2019",
        "content": [
            [
                "assets/images/blog/blg-img.png",
                "Проход продолжает видеть частое использование, с lorem ipsum",
                "#",
                "Aldus Corporation, которая впоследствии объединилась с Adobe Systems, помогли принести Lorem Ipsum в информационную эпоху с ее 1985 флагманского"
            ],
            [
                "assets/images/blog/blg-img.png",
                "Проход продолжает видеть частое использование, с lorem ipsum",
                "#",
                "Aldus Corporation, которая впоследствии объединилась с Adobe Systems, помогли принести Lorem Ipsum в информационную эпоху с ее 1985 флагманского"
            ],
            [
                "assets/images/blog/blg-img.png",
                "Проход продолжает видеть частое использование, с lorem ipsum",
                "#",
                "Aldus Corporation, которая впоследствии объединилась с Adobe Systems, помогли принести Lorem Ipsum в информационную эпоху с ее 1985 флагманского"
            ]
        ]
    },
    {
        "date": "23/04/2019",
        "month": "March",
        "year": "2019",
        "content": [
            [
                "assets/images/blog/blg-img.png",
                "Проход продолжает видеть частое использование, с lorem ipsum",
                "#",
                "Aldus Corporation, которая впоследствии объединилась с Adobe Systems, помогли принести Lorem Ipsum в информационную эпоху с ее 1985 флагманского"
            ],
            [
                "assets/images/blog/blg-img.png",
                "Проход продолжает видеть частое использование, с lorem ipsum",
                "#",
                "Aldus Corporation, которая впоследствии объединилась с Adobe Systems, помогли принести Lorem Ipsum в информационную эпоху с ее 1985 флагманского"
            ],
            [
                "assets/images/blog/blg-img.png",
                "Проход продолжает видеть частое использование, с lorem ipsum",
                "#",
                "Aldus Corporation, которая впоследствии объединилась с Adobe Systems, помогли принести Lorem Ipsum в информационную эпоху с ее 1985 флагманского"
            ]
        ]
    },
    {
        "date": "22/08/2018",
        "month": "August",
        "year": "2018",
        "content": [
            [
                "assets/images/blog/blg-img.png",
                "Проход продолжает видеть частое использование, с lorem ipsum",
                "#",
                "Aldus Corporation, которая впоследствии объединилась с Adobe Systems, помогли принести Lorem Ipsum в информационную эпоху с ее 1985 флагманского"
            ],
            [
                "assets/images/blog/blg-img.png",
                "Проход продолжает видеть частое использование, с lorem ipsum",
                "#",
                "Aldus Corporation, которая впоследствии объединилась с Adobe Systems, помогли принести Lorem Ipsum в информационную эпоху с ее 1985 флагманского"
            ],
            [
                "assets/images/blog/blg-img.png",
                "Проход продолжает видеть частое использование, с lorem ipsum",
                "#",
                "Aldus Corporation, которая впоследствии объединилась с Adobe Systems, помогли принести Lorem Ipsum в информационную эпоху с ее 1985 флагманского"
            ]
        ]
    },
    {
            "date": "03/07/2017",
            "month": "July",
            "year": "2017",
            "content": [
            [
                "assets/images/blog/blg-img.png",
                "Проход продолжает видеть частое использование, с lorem ipsum",
                "#",
                "Aldus Corporation, которая впоследствии объединилась с Adobe Systems, помогли принести Lorem Ipsum в информационную эпоху с ее 1985 флагманского"
            ],
            [
                "assets/images/blog/blg-img.png",
                "Проход продолжает видеть частое использование, с lorem ipsum",
                "#",
                "Aldus Corporation, которая впоследствии объединилась с Adobe Systems, помогли принести Lorem Ipsum в информационную эпоху с ее 1985 флагманского"
            ]
        ]
    },
    {
            "date": "03/07/2016",
            "month": "July",
            "year": "2016",
            "content": [
            [
                "assets/images/blog/blg-img.png",
                "Проход продолжает видеть частое использование, с lorem ipsum",
                "#",
                "Aldus Corporation, которая впоследствии объединилась с Adobe Systems, помогли принести Lorem Ipsum в информационную эпоху с ее 1985 флагманского"
            ],
            [
                "assets/images/blog/blg-img.png",
                "Проход продолжает видеть частое использование, с lorem ipsum",
                "#",
                "Aldus Corporation, которая впоследствии объединилась с Adobe Systems, помогли принести Lorem Ipsum в информационную эпоху с ее 1985 флагманского"
            ]
        ]
    }
    
]    
module.exports = arr;